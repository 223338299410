import React from "react"
import BodyLayout from "../components/bodyLayout"
import { Col, Row, Icon } from "antd"
import Img from "gatsby-image"
import {WindowDimensionsCtx} from '../components/windowDimensionsProvider';
import SEO from '../components/SEO'


export default ({data}) => {

  return (
  <BodyLayout color={'black'}>
  <SEO title={'Statiste - about'} description={"Baptiste Rios consultant indépendant en data science"} />
  <WindowDimensionsCtx.Consumer>{device => (
    <div className="notenoughcontent">
    <Row style={{paddingTop:30}}>
    <Col xs={{span:22,offset:1}} md={{span:18,offset:3}}>
      <Row type="flex" align="top" justify="space-between">
        <Col span={8} align="center">
        <Img style={{maxWidth:(device==='mobile') ? 100: 200, maxHeight:(device==='mobile') ? 140: 280}} fixed={data.profile.childImageSharp.fixed} />
        </Col>
        <Col span={15} offset={1} align="justify">
        <h2>Baptiste Rios</h2>
        <h4>En 2019, après 2 ans en start up et 3 ans dans un cabinet de conseil pionnier en data science, j'ai trouvé opportun de démarrer mon propre projet professionel</h4>
        </Col>
      </Row>
    </Col>
    </Row>
    <Row type="flex" style={{marginTop:25}}>
      <Col xs={{span:22,offset:1}} md={{span:16,offset:4}}>
        <h4>Avec quelles garanties?</h4>
        <p><Icon type="right"/>Une flexibilité maximale</p>
        <p><Icon type="right"/>Que votre interlocuteur est bien celui qui sait faire et qui fait</p>
        <p><Icon type="right"/>Qu'aucune problématique technique ne saura mettre en péril le projet</p>
      </Col>
    </Row>
    </div>
  )}
  </WindowDimensionsCtx.Consumer>
  </BodyLayout>
)}

export const query = graphql`
  {
    site {
      siteMetadata {
        title
        breakpoint
      }
    }
    profile: file(relativePath: {eq:"img/profile.jpg"}) {
      childImageSharp {
        fixed(width: 300, height: 450) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    cvFR: allFile(filter: {extension: {eq: "pdf"}, relativePath: {eq: "pdf/CV_baptiste_rios_FR_2019.pdf"}}) {
      edges {
        node {
          publicURL
        }
      }
    }
    cvEN: allFile(filter: {extension: {eq: "pdf"}, relativePath: {eq: "pdf/CV_baptiste_rios_EN_2019.pdf"}}) {
      edges {
        node {
          publicURL
        }
      }
    }
  }
  `
